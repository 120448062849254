<template>
	<div class="relative h-full">
		<div class=" text-9xl flex flex-col space-y-10 justify-center items-center h-full p-5">
			<img src="@/assets/NUBETECH.png" alt="" class="h-20">
			<n-card title="激活系統" hideFooter>
				<div class="flex flex-col space-y-5">
					<qrcode class="mx-auto" :value="deviceId" :options="{ width: 200 }" tag="img"></qrcode>
					<div class="text-left text-lg text-black">
						<p>NUBE{{appType}}</p>
						<p class="text-sm text-muted">{{ deviceId }}</p>
						<p class="text-sm text-muted">v{{ version }}</p>
					</div>
				</div>
			</n-card>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { appType, version } from '../../package.json'
import moment from 'moment'

export default {
	data() {
		return {
			deviceId: localStorage.getItem('deviceId'),
			appType: appType,
			version: version,
		}
	},

	computed: {
		...mapGetters(['currentDevice']),
	},

	watch: {
		currentDevice: async function (value) {
			try {
				if (!value.licenses.find(l => l.appType === this.appType) || value.status !== 'ACTIVE') return
				this.$store.dispatch('setLoading', { status: true })
				const startCounter = Number(moment().utcOffset(8).format('YYMMDD') + '000')
				const endCounter = Number(moment().utcOffset(8).format('YYMMDD') + '999')
				await this.$store.dispatch('bindCurrentMerchant', { id: value.merchantId })
				await this.$store.dispatch('bindCurrentShop', { id: value.shopId })
                await this.$store.dispatch('bindQueues', { shopId: value.shopId, startCounter: startCounter, endCounter: endCounter, status:['CALL'] })
				await this.$store.dispatch('bindSaleOrders', { shopId: value.shopId, startCounter: startCounter, endCounter: endCounter })
				this.$store.dispatch('setLoading', { status: false })
				return this.$router.push('/signage')
			} catch (e) {
				console.error(e)
				this.$store.dispatch('setError', { errorMessage: e.message, status: true })
				this.$store.dispatch('setLoading', { status: false })
			}
		},
	},
}
</script>